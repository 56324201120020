<template>
  <moe-page title="查看问卷">
    <moe-form
      class="default-form"
      :showBack="false"
      :showClose="true">
      <div style="width: 50%;" class="margin-0-auto">
        <moe-describe-list title="问卷详情" :col="1">
          <moe-describe-item label="问卷名称">
            <div>{{ surveyData.name }}</div>
            <div class="color-info font-10 lh1 mt-10">问卷名称将作为问卷页面标题展示给用户</div>
          </moe-describe-item>
          <moe-describe-item label="问卷时间">{{ surveyData.startTime }} 至 {{ surveyData.endTime }} </moe-describe-item>
          <moe-describe-item label="问卷奖励">
            <div v-if="surveyData.sendLottery" class="df aic wsn">
              {{ `抽奖活动编号 ${surveyData.lotteryId} 赠送抽奖 ${surveyData.lotteryCount} 次` }}
            </div>
            <div v-else>无</div>
          </moe-describe-item>
          <moe-describe-item label="问卷限制">
            {{ `每个ID限制填写 ${surveyData.submitLimit} 次` }}
          </moe-describe-item>
          <moe-describe-item label="背景图片">
            <moe-image v-if="surveyData.img" :src="surveyData.img" width="100px" height="100px"></moe-image>
          </moe-describe-item>
          <moe-describe-item label="问卷介绍">
            <el-input type="textarea" readonly :autosize="{ minRows: 4, maxRows: 10 }" v-model="surveyData.description" placeholder="请输入问卷介绍" maxlength="500"></el-input>
          </moe-describe-item>
        </moe-describe-list>

        <moe-describe-list title="设置题目" :col="1">
          <moe-describe-item>
            <moe-card class="mb-20" v-for="(item, index) in surveyData.questions" :key="index">
              <moe-describe-item label="题目类型">
                {{ $moe_format.getQuestionnaireSurveyTypeList(item.type) }}
              </moe-describe-item>

              <moe-describe-item>
                <div class="df aifs">
                  <div class="mr-5 color-danger" v-if="item.required">*</div>
                  <div class="mr-10">{{ `${index + 1}.` }}</div>
                  <div>{{ item.title }}</div>
                </div>
              </moe-describe-item>

              <div class="ml-50" v-if="item.images">
                <moe-image class="mr-5" :src="imageItem" v-for="(imageItem, imageIndex) in item.images.split(',')" :key="imageIndex" width="200px" height="200px" />
              </div>

              <moe-describe-item :label="`选项${questionIndex+1}`" v-for="(questionItem, questionIndex) in item.options" :key="questionIndex">
                {{ questionItem.title }}
              </moe-describe-item>

              <moe-describe-item v-if="['TEXT'].includes(item.type)">
                <el-input type="textarea" readonly :autosize="{ minRows: 2, maxRows: 4 }" placeholder="回答区域" maxlength="500"></el-input>
              </moe-describe-item>
            </moe-card>
          </moe-describe-item>
        </moe-describe-list>
      </div>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'QuestionnaireSurveyDetail',
  data() {
    return {
      surveyData: {},
    }
  },
  methods: {
    /** 查询问卷详情 */
    async req_getSurveyDetail() {
      if (this.$route.query.id) {
        const { code, result, message } = await this.$moe_api.QUESTIONNAIRESURVEY_API.getSurveyDetail({ id: this.$route.query.id });
        if (code === 200) {
          const { id, name, startTime, endTime, sendLottery, lotteryId, lotteryCount, submitLimit, img, description, questions } = result;
          this.surveyData = {
            id,
            name,
            startTime,
            endTime,
            sendLottery,
            lotteryId: sendLottery ? lotteryId : '',
            lotteryCount: sendLottery ? lotteryCount : '',
            submitLimit,
            img,
            description,
            questions,
          };
        } else {
          this.$moe_msg.error(message);
        }
      }
    }
  },
  mounted() {
    this.req_getSurveyDetail();
  },
}
</script>